import EnvUtils, { EnvUtilsOptions } from '@vinka/env-utils';

const envUtilsOptions: EnvUtilsOptions = {
    prefix: 'REACT_APP_LOKI_',
};
// take window._env_ object and parse that for envUtil configs
const envObject = (window as any)._env_;
envUtilsOptions.envObject = envObject;
const envUtil = new EnvUtils(envUtilsOptions);

// definitions for config values
// AppConfig will do overrides for these values based on envConfig values
// @todo: now when using EnvUtils, we could get rid of envConfig.
const defConfig = {
    api: {
        url: 'https://loki-api.demo.vinka.cloud',
    },
    google: {
        country: 'fi',
    },
    app: {
        rootURL: '/',
        pingExpiration: 300, // seconds
    },
    auth0: {
        domain: 'vinka.eu.auth0.com',
        clientId: '3iTP4pksqTn1Tad5O3asZnRUgaWFGXq3',
        audience: 'https://loki-api.vinka.cloud',
    },
    map: {
        apiUrl: 'https://api.tomtom.com',
        tilesUrl: 'https://{s}.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key={apikey}',
        key: 'bxMRHyssAfsV8oGUESpsfIow6KU66d4q', // Vinka general
        countrySet: 'FI', // the lookup results will be restricted by the provided country
        center: envUtil.getValue<CoordinatePair>({
            name: 'MAP_CENTER',
            translator: (envVar) => {
                const [latStr, lngStr] = envVar.split(',');
                const lat = parseFloat(latStr);
                const lng = parseFloat(lngStr);
                if (isNaN(lat) || isNaN(lng)) {
                    throw Error('Invalid map center: ' + envVar);
                }
                return [lat, lng];
            },
            defaults: {
                '*': [61.497752, 23.860954],
            },
        }),
    },
    ui: {
        customerStyle: '',
        languages: [
            { id: 'fi', name: 'Suomi' },
            { id: 'en', name: 'English' },
        ],
    },
    customerLocale: '',
};

const envConfig = {
    meneva: {
        app: {
            rootURL: '/',
        },
        map: {
            key: 'bxMRHyssAfsV8oGUESpsfIow6KU66d4q',
        },
    },
    'meneva-um': {
        app: {
            rootURL: '/',
        },
        map: {
            key: 'bxMRHyssAfsV8oGUESpsfIow6KU66d4q',
        },
    },
    'meneva-test': {
        app: {
            rootURL: '/',
        },
        map: {
            key: 'bxMRHyssAfsV8oGUESpsfIow6KU66d4q',
        },
    },
    'tuomi-test': {
        customerLocale: 'tuomi',
        map: {
            key: 'zLbYylpom7Pa2Xzl901gcs2QhwShhdHJ',
        },
    },
    'tuomi-prod': {
        customerLocale: 'tuomi',
        map: {
            key: 'zLbYylpom7Pa2Xzl901gcs2QhwShhdHJ',
        },
    },
    localhost: {
        app: {
            rootURL: '/',
        },
        api: {
            url: 'http://localhost:9320',
            // url: 'https://demo.vinka.cloud/loki-api',
            // url: 'https://loki-api.tuomi-prod.vinka.cloud',
            // url: 'https://tuomi-test.vinka.cloud/loki-api',
        },
        // auth0: {
        //     domain: 'vinka-test.eu.auth0.com',
        //     clientId: 'BU2q7A6h1nChqOxvpMiafExnh4F4wTWS',
        // },
        map: {
            key: 'bxMRHyssAfsV8oGUESpsfIow6KU66d4q',
        },
    },
    // and so on...
};

class AppConfig {

    constructor() {
        // check if there are any overrides available for the environments. If so - apply them
        const [env, rootURL] = AppConfig.env;
        if (env) {
            const url =
                rootURL === '/'
                    ? `https://loki-api.${env}.vinka.cloud`
                    : `https://${env}.vinka.cloud/loki-api/`;
            const overrideDefaultConfig = Object.assign(
                { api: { url }, app: { rootURL } },
                envConfig[env]
            );

            AppConfig.deepMerge(defConfig, overrideDefaultConfig);

            // custom customer localization resources based on envConfig or env value (e.g. tuomi-test)
            // customer config may define custom locale string or then use env name
            defConfig.customerLocale = (envConfig[env] || {}).customerLocale || env;
        }
    }

    // merge recursively
    static deepMerge(target, source) {
        Object.keys(source) // start iterating through the own keys of the object
            .forEach((k) =>
                source[k] instanceof Object
                    ? AppConfig.deepMerge(target[k] || (target[k] = {}), source[k])
                    : (target[k] = source[k])
            );
    }

    static get env(): [string, string] {
        const [app, env]: string[] = window.location.hostname.split('.');

        if (app === 'localhost') {
            // localhost
            return ['localhost', '/'];
        } else if (app === 'loki') {
            // https://loki.demo.vinka.cloud
            return [env, '/'];
        } else {
            // https://demo.vinka.cloud/loki/
            return [app, '/loki/'];
        }
    }
}

const appConfig = new AppConfig();

export default defConfig;

export const getEnv = AppConfig.env;
